// http://ionicframework.com/docs/theming/

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import './theme/variables.scss';

cdk-virtual-scroll-viewport {
    height: 100%;
    width: 100%;
}

// https://stackoverflow.com/questions/32202874/ion-refresher-spinner-not-showing
ion-refresher {
	z-index: 10;

    .refresher-pulling-icon ion-icon {
        color: #258290;
    }
}

ion-spinner {
    display: block;
    margin: auto;
}

// Font
@font-face {
	font-family: 'Roboto';
	src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

:root {
	--ion-font-family: "Roboto", "Helvetica Neue", sans-serif;
}

ion-datetime {
    color: var(--ion-color-dark);;
}

.error-backdrop {
	position: absolute;
	display: block;
	--background: rgba(0, 0, 0, 0.8);
	margin-top: -15%;
	--width: 100%;
	--height: 100%;
	top: 0%;
	left: 0%;
}

// ion-modal ion-footer {
// 	margin-bottom: 5px !important;
// }

// :root, ion-content, ion-list, ion-item {
// 	--background: var(--ion-color-light);
// }

cdk-virtual-scroll-viewport ion-list ion-item {
    --min-height: 80px;
}

ion-infinite-scroll, ion-infinite-scroll-content, cdk-virtual-scroll-viewport, ion-content, ion-footer {
	background-color: var(--ion-color-light);
}

ion-virtual-scroll, cdk-virtual-scroll-viewport, ion-list.skeleton {
	ion-item ion-label {
		padding-left: 5px;
	}
}

// Header and footer
ion-header, ion-tab-bar {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Header and tab bar
ion-header > ion-toolbar, ion-tab-bar, ion-tab-bar > ion-title {
	height:56px;
}

ion-header ion-buttons {
	height:50px;
}

// Header and Toolbar
ion-header > ion-toolbar {
	@extend .propool-background-gradient;
}

ion-header > ion-toolbar,
ion-header ion-text {
	color:white;
}

// Title
ion-header >  ion-toolbar ion-title {
	font-size:1.5rem;
	font-weight:500;
	font-family: 'Roboto';
	height:56px;
}

ion-header > ion-toolbar ion-icon {
	color:white;
}

ion-header ion-back-button {
	--color:white !important;
}

// footer

ion-footer, ion-footer > ion-toolbar {
	--background: transparent;
}

ion-footer > ion-toolbar {
	padding-bottom:10px;
}

ion-footer > ion-toolbar {
	--padding-top: 0px !important;
	--padding-start: 0px !important;
	--padding-right: 0px !important;
	--padding-end: 0px !important;
	--border-width:0px !important;
}

// ion-label within ion-item should be clickable
ion-item {
	ion-label {
		z-index: inherit;
		pointer-events: visible !important;
	}
}

// Buttons
ion-button.ion-color-primary {
	@extend .propool-color-base-gradient;
}

// Buttons
ion-button.ion-color-action {
	@extend .propool-color-base-action;
}

// searchbar
ion-searchbar {
	--background: white !important;
	--cancel-button-color: white !important;
}

// From old project:
.propool-logo {
	display: block;
	margin: 0 auto;
	width: 75%;
	padding: 10px 25px 25px 25px;
	max-width: 350px;
}

h1 {
	text-align: center;
	padding: 20px 0;
}

a {
	color: inherit !important;
}

textarea {
	max-width: 100%;
	width: 100%;
	height: 15vh;
}

.text-input {
	margin-left: 0px !important;
}

.helper-text {
	margin:0 auto;
	width:300px;
	padding:25px 0px 25px 0px;
}

.helper-text p:first-child {
	margin-top: 15px
}

.helper-text p {
	font-weight: bold;
}

.helper-text ion-icon {
	margin-right: 5px;
}

.helper-text span {
	display: inline;
	padding-bottom: 10px;
}

.color-green {
	color: #34b080;
}

.btn-popover {
	margin-bottom: 0px !important;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.hidden {
	visibility: hidden;
}

.align-items-center {
	align-items: center;
}

.action-label {
	color: #1652a1 !important;
	padding: 0 0 10px 0 !important;
}

.has-footer {
	margin-bottom: 110px !important;
  }

.line-break {
	white-space: pre-line;
	font-size: 16px !important;
	line-height: 16px !important;
	padding-bottom: 2px;
}

.no-overflow {
	overflow: initial !important;
}

.no-overflow .select-text {
	margin-left: -60px !important;
	text-align: right;
}

.gradient-footer {
	@extend .propool-background-native-gradient;

	ion-button, p {
		color: white !important;
	}

	ion-icon {
		width: 32px;
		height: 32px;
	}

	ion-col {
        text-align: center;
	}

	ion-buttons {
		display: inline;
	}

	ion-grid {
		padding: 0;
	}
}

// .swap {
// 	-webkit-transform: rotate(90deg);
// 	-moz-transform: rotate(90deg);
// 	-o-transform: rotate(90deg);
// 	-ms-transform: rotate(90deg);
// 	transform: rotate(90deg);
// }

/// Skeleton loading
// .skelet-loading-h2 {
// 	height: 16px;
// 	width: 150px;
// 	background-color: #dedede;
// 	margin: 8px 0 !important;
// 	position: relative;
// 	overflow: hidden;
// 	border-radius: 5px;
// }

// .skelet-loading-h2:before {
// 	display: block;
// 	position: absolute;
// 	content: "";
// 	left: -25%;
// 	width: 25%;
// 	height: 16px;
// 	background-color: #d2d2d2;
// 	animation: loading 2s linear infinite;
// }

// .skelet-loading-h2-center {
// 	display: block;
// 	height: 16px;
// 	width: 150px;
// 	background-color: #dedede;
// 	margin: 8px auto !important;
// 	position: relative;
// 	overflow: hidden;
// 	border-radius: 5px;
// }

// .skelet-loading-h2-center:before {
// 	display: block;
// 	position: absolute;
// 	content: "";
// 	left: -25%;
// 	width: 25%;
// 	height: 16px;
// 	background-color: #d2d2d2;
// 	animation: loading 2s linear infinite;
// }

// .skelet-loading-p {
// 	height: 16px;
// 	width: 125px;
// 	background-color: #dedede;
// 	margin: 8px 0 !important;
// 	position: relative;
// 	overflow: hidden;
// 	border-radius: 5px;
// }

// .skelet-loading-p:before {
// 	display: block;
// 	position: absolute;
// 	content: "";
// 	left: -25%;
// 	width: 25%;
// 	height: 18px;
// 	background-color: #d2d2d2;
// 	animation: loading 2s linear infinite;
// }

// .skelet-loading-p-right {
// 	height: 16px;
// 	width: 65px;
// 	background-color: #dedede;
// 	margin-top: -5px !important;
// 	position: relative;
// 	overflow: hidden !important;
// 	border-radius: 5px;
// }

// .skelet-loading-p-right:before {
// 	display: block;
// 	position: absolute;
// 	content: "";
// 	left: -25%;
// 	width: 25%;
// 	height: 18px;
// 	background-color: #d2d2d2;
// 	animation: loading 2s linear infinite;
// }

// .skelet-loading-image-thumbnail {
// 	border-radius: 50%;
// 	background-color: #dedede;
// 	border-radius: 50%;
// 	padding: 1px !important;
// 	position: relative !important;
// 	overflow: hidden !important;
// }

// .skelet-loading-image-thumbnail:before {
// 	display: block;
// 	position: absolute;
// 	content: "";
// 	left: -25%;
// 	width: 25%;
// 	height: 80px;
// 	background-color: #d2d2d2;
// 	animation: loading 2s linear infinite;
// 	z-index: 100;
// }

// .skelet-loading-profile-image {
// 	display: block;
// 	margin: 0 auto;
// 	height: 150px;
// 	width: 150px;
// 	border-radius: 50%;
// 	background-color: #dedede;
// 	padding: 1px !important;
// 	position: relative !important;
// 	overflow: hidden !important;
// }

// Discipline image list

.discipline-image-container { /* TODO: Move to concrete files GENERIC stuff or create component */
	border: 0;
	float: left;
	position: relative;
}

.discipline-image {
	border: 0;
	position: absolute;
	bottom: 0;
	right: 0;
	height: 24px !important;
	width: 24px !important;
}

.tournament-image {
	border: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 24px !important;
	width: 24px !important;
}

.page-loading-spinner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

ion-refresher-content {
	background-color: transparent !important;
	padding-bottom: 28px;
}

h2.username {
	font-size: 2.0rem;
	margin-bottom: 10px;
}

.open_picker, ion-picker {
	touch-action: none;
}

ion-header ion-button {
	--color: white;
}

ion-modal {
    .modal-wrapper {
		position: absolute !important;
		bottom: 0 !important;
        height: 93% !important;
    }
}

.ellipsis-full {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.profile-image { // TODO: This is duplicated code from profile, player and creatematch component. Make shared <avatar>
    display: block;
	margin: 0 auto;
	margin-top: 15px; // except for this?
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background: #1652a1; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #1652a1 , #34b080); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #1652a1 , #34b080); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #1652a1 , #34b080); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #1652a1 , #34b080); /* Standard syntax */
    border-radius: 50%;
    padding: 2px !important;
}

.profile-image-thumbnail { // TODO: Make shared <avatar-thumbnail>
	border-radius: 50%;
	background: #1652a1; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(left, #1652a1 , #34b080); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, #1652a1 , #34b080); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, #1652a1 , #34b080); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, #1652a1 , #34b080); /* Standard syntax */
	padding: 1px !important;
}

/*

old app.component.scss

// http://ionicframework.com/docs/v2/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

// Selected images

.select-display-inline {
    display: inline;
}

.select-scroll {
    height: 85px !important;
    white-space: nowrap !important;
}

.select-scroll .scroll-content {
    padding: 10px !important;
}

.select-container {
    display: inline-block;
    width: 60px;
    margin: 0px 2px;
}

.select-image-display {
    display: inline-block;
    margin: 0px 5px;
}

.select-image-thumbnail {
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.select-image-container {
    position: relative;
    margin: 0px 0px 15px 0px;
}

.select-image-add {
    border: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20px !important;
    width: 20px !important;
}

.select-image-remove {
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 20px !important;
    width: 20px !important;
}

.select-image-text {
    display: block;
    margin-top: -15px;
    text-align: center;
    font-size: 12px
}

.ellipsis-60 {
    width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.padding-bottom-list .list:last-child {
    margin-bottom: 55px !important;
}

// THEMING - CONCEPT

.content-toolbar .bar-button {
    color: #333;
}

.toolbar-title,
.bar-button {
    color: #fff;
}

.bar-button:active,
.bar-button:hover,
.bar-button:focus {
    color: #fff !important;
}

.fixed-content,
.scroll-content {
    background: #f2f2f2 !important;
}

.item-block {
    background: #f2f2f2;
}

.list-header {
    border-bottom: 1px solid #dedede;
}

.list-label {
    background: #1652a1;
    background: -webkit-gradient(linear, left top, right top, from(#1652a1), to(#34b080));
    background: linear-gradient(to right, #1652a1, #34b080);
    padding: 4px 8px;
    border-radius: 5px;
    color: #fff !important;
    font-size: 14px !important;
    float: right;
    position: relative;
    top: 2px;
}

.button-default {
    background: #1652a1; /* For browsers that do not support gradients
    background: -webkit-linear-gradient(left, #1652a1 , #34b080); /* For Safari 5.1 to 6.0
    background: -o-linear-gradient(right, #1652a1 , #34b080); /* For Opera 11.1 to 12.0
    background: -moz-linear-gradient(right, #1652a1 , #34b080); /* For Firefox 3.6 to 15
    background: linear-gradient(to right, #1652a1 , #34b080); /* Standard syntax
}

.button-outline  {
    border: 1px solid transparent;
    background-color: #f2f2f2;
    background-image: linear-gradient(to right, #1652a1, #34b080), linear-gradient(to bottom ,#34b080, #34b080 ), linear-gradient(to left, #34b080, #1652a1), linear-gradient(to top, #1652a1, #1652a1);
    background-origin: border-box;
    background-size: 100% 2px, 2px 100%, 100% 2px, 2px 100%;
    background-position: top left, top right, bottom right, bottom left;
    background-repeat: no-repeat;
}

.button-outline .button-inner {
    width: 100%;
    color: #1652a1 !important;
    background-image: -webkit-linear-gradient(left, #1652a1, #34b080) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.button-secondary {
    color: #fff;
    background: #1652a1;
    background: -webkit-gradient(linear, left top, right top, from(#e18f23), to(#e1bf23));
    background: linear-gradient(to right, #e18f23, #e1bf23);
}

.button-locked {
    color: #fff;
    background: #c7c7c7 !important;
}

.button-extra-margin {
    margin-bottom: 10px
}
*/
